import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/* eslint-disable react/jsx-no-target-blank */

/** @jsxImportSource @emotion/react */
import { Typography } from '@vc-workspace/utils-typography';
import { curatedCategoryListItem, curatedCategoryList, categoryImage } from './homepage-category-widget.module.style';
import { getDisplayImageObj } from '@vc-workspace/utils-helpers';
import { IndievibeAmplitudeEventNames, sendAmplitudeData } from '@vc-workspace/utils-indievibe-amplitude';
import { PageTypes } from '@vc-workspace/utils-constants';
/* eslint-disable-next-line */

export function HomepageCategoryWidget(props) {
  return _jsx("div", {
    css: curatedCategoryList,
    children: props.categories.map(function (curatedCategory) {
      var displayImage = getDisplayImageObj(curatedCategory.displayImages[0], '1');
      return _jsx("a", {
        href: "/p/".concat(curatedCategory.redirectionPage.slug, "/").concat(curatedCategory.redirectionPage.usId),
        target: props.context.isMobileView ? '_self' : '_blank',
        onClick: function onClick() {
          return sendAmplitudeData(IndievibeAmplitudeEventNames.CATEGORY_INTERACTION, {
            source: PageTypes.HOMEPAGE
          });
        },
        children: _jsxs("div", {
          css: curatedCategoryListItem,
          children: [_jsxs("picture", {
            children: [_jsx("source", {
              srcSet: displayImage.webP,
              type: "image/webp"
            }), _jsx("img", {
              src: displayImage.url,
              css: categoryImage
            })]
          }), _jsx(Typography, {
            type: "Body2",
            color: "white",
            className: "text-center pt-2",
            children: curatedCategory.title
          })]
        })
      }, curatedCategory.redirectionPage.usId);
    })
  });
}
export default HomepageCategoryWidget;