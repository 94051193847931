import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { containerStyles, searchContainer } from './homepage-navbar.module.style';
import NavbarProfile from '../navbar-profile/navbar-profile';
import { Typography } from '@vc-workspace/utils-typography';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
import { useContext } from 'react';
import { NavItemsLabel } from '../mobile-bottom-navbar/mobile-bottom-navbar';
import NavbarCheckout from '../navbar-checkout/navbar-checkout';
import NavbarWishlist from '../navbar-wishlist/navbar-wishlist';
import DropdownSearch from '../dropdown-search/dropdown-search';
import { IndievibeLogo } from '@vc-workspace/indievibe-logo';
import SignupNavbarCta from '../signup-navbar-cta/signup-navbar-cta';
import LoginNavbarCta from '../login-navbar-cta/login-navbar-cta';
/* eslint-disable-next-line */

export function HomepageNavbar(props) {
  var _useContext = useContext(AppConfigContext),
      setSelectedTab = _useContext.setSelectedTab,
      isLoggedIn = _useContext.isLoggedIn;

  function onSearchClick() {
    setSelectedTab(NavItemsLabel.EXPLORE);
  }

  return _jsx("div", {
    css: containerStyles,
    children: _jsxs("div", {
      className: "page-cushion-container pure-g",
      children: [_jsx("div", {
        className: props.context.isMobileView ? 'pure-u-1-2 flex items-center' : 'pure-u-1-5',
        children: _jsx(IndievibeLogo, {
          theme: 'WHITE',
          width: props.context.isMobileView ? '110px' : '150px'
        })
      }), _jsxs("div", {
        className: "".concat(props.context.isMobileView ? 'pure-u-1-2' : 'pure-u-4-5', " flex items-center justify-end"),
        children: [props.context.isMobileView ? _jsx("div", {
          css: searchContainer,
          onClick: isLoggedIn ? onSearchClick : null,
          className: isLoggedIn ? 'border-b border-solid border-white' : '',
          children: isLoggedIn ? _jsxs(_Fragment, {
            children: [_jsx("img", {
              src: "/_mp-images/search_white.svg"
            }), _jsx(Typography, {
              type: "Body1",
              color: "white",
              className: "pl-1",
              children: "Search product"
            })]
          }) : _jsx(SignupNavbarCta, {})
        }) : null, !props.context.isMobileView ? _jsxs(_Fragment, {
          children: [_jsx("div", {
            className: "mr-4 inline-block",
            children: _jsx(DropdownSearch, {
              disableBackdrop: true,
              isImmersive: true
            })
          }), _jsx(NavbarWishlist, {
            context: props.context,
            whiteTheme: true
          }), isLoggedIn ? _jsxs(_Fragment, {
            children: [_jsx(NavbarCheckout, {
              context: props.context,
              whiteTheme: true
            }), _jsx("div", {
              className: "inline-block ml-4",
              children: _jsx(NavbarProfile, {
                context: props.context,
                whiteTheme: true
              })
            })]
          }) : _jsxs(_Fragment, {
            children: [_jsx("div", {
              className: "mr-4",
              children: _jsx(LoginNavbarCta, {
                theme: "white"
              })
            }), _jsx(SignupNavbarCta, {})]
          })]
        }) : null]
      })]
    })
  });
}
export default HomepageNavbar;