import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { ImageAccessibility, ImageTypes } from '@vc-workspace/utils-constants';
import { getImagesByType } from '@vc-workspace/utils-helpers';
import { Typography } from '@vc-workspace/utils-typography';
import { containerStyles, bannerImage, contentContainer, infoBannerContainer, infoBannerIcon, infoBannerItemContainer } from './homepage-hero-banner.module.style';
import HomepageCategoryWidget from '../homepage-category-widget/homepage-category-widget';
import { useContext } from 'react';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
import { FullWidthImageCarousel } from '@vc-workspace/full-width-image-carousel';
/* eslint-disable-next-line */

export function HomepageHeroBanner(props) {
  var _props$data$data$cura;

  var displayImage = getImagesByType(props.data.data.banners[0].images, ImageTypes.HomePageBanner, '3', props.context.isMobileView ? ImageAccessibility.Mobile : ImageAccessibility.Desktop);

  var _useContext = useContext(AppConfigContext),
      userDetails = _useContext.userDetails,
      isLoggedIn = _useContext.isLoggedIn;

  var infoLayout = [_jsx("div", {
    children: _jsxs("div", {
      css: infoBannerItemContainer,
      children: [_jsx("img", {
        src: "/_mp-images/homepage_unique.png",
        css: infoBannerIcon
      }), _jsxs("div", {
        className: "pl-4",
        children: [_jsx(Typography, {
          type: props.context.isMobileView ? "SubHeading1" : "Headline_H2",
          color: "navy",
          bold: true,
          children: "High-quality and unique products"
        }), _jsxs(Typography, {
          type: props.context.isMobileView ? "Body1" : "SubHeading1",
          color: "cornflower_blue",
          children: ["Explore 40,000+ unique, handmade items made ", !props.context.isMobileView ? _jsx("br", {}) : null, " by Indian artisans & craftsmen"]
        })]
      })]
    })
  }, "item-3"), _jsx("div", {
    children: _jsxs("div", {
      css: infoBannerItemContainer,
      children: [_jsx("img", {
        src: "/_mp-images/homepage_min_order.png",
        css: infoBannerIcon
      }), _jsxs("div", {
        className: "pl-4",
        children: [_jsx(Typography, {
          type: props.context.isMobileView ? "SubHeading1" : "Headline_H2",
          color: "navy",
          bold: true,
          children: "No minimum order value"
        }), _jsxs(Typography, {
          type: props.context.isMobileView ? "Body1" : "SubHeading1",
          color: "cornflower_blue",
          children: ["Explore our products to your heart's content,", !props.context.isMobileView ? _jsx("br", {}) : null, " no minimum order value required"]
        })]
      })]
    })
  }, "item-2"), _jsx("div", {
    children: _jsxs("div", {
      css: infoBannerItemContainer,
      children: [_jsx("img", {
        src: "/_mp-images/homepage_custom_order.png",
        css: infoBannerIcon
      }), _jsxs("div", {
        className: "pl-4",
        children: [_jsx(Typography, {
          type: props.context.isMobileView ? "SubHeading1" : "Headline_H2",
          color: "navy",
          bold: true,
          children: "Custom manufacturing simplified"
        }), _jsxs(Typography, {
          type: props.context.isMobileView ? "Body1" : "SubHeading1",
          color: "cornflower_blue",
          children: ["Custom designs or private label? We work with you", !props.context.isMobileView ? _jsx("br", {}) : null, " to bring your ideas to life."]
        })]
      })]
    })
  }, "item-1")];
  return _jsxs(_Fragment, {
    children: [_jsxs("div", {
      css: containerStyles,
      children: [_jsxs("picture", {
        children: [_jsx("source", {
          type: "image/webp",
          srcSet: displayImage.webP
        }), _jsx("img", {
          src: displayImage.url,
          css: bannerImage
        })]
      }), _jsx("div", {
        css: contentContainer,
        children: _jsxs("div", {
          className: "page-container",
          children: [_jsx(Typography, {
            type: props.context.isMobileView ? 'Headline_H2' : 'Heading2',
            color: "white",
            className: "md:pb-20 pb-6 md:text-center",
            bold: !props.context.isMobileView,
            semi_bold: props.context.isMobileView,
            children: _jsx("p", {
              dangerouslySetInnerHTML: {
                __html: props.data.data.banners[0].bannerText
              }
            })
          }), _jsx(HomepageCategoryWidget, {
            categories: ((_props$data$data$cura = props.data.data.curatedCategories) === null || _props$data$data$cura === void 0 ? void 0 : _props$data$data$cura.data) || [],
            context: props.context
          })]
        })
      })]
    }), props.context.isMobileView ? _jsx("div", {
      css: infoBannerContainer,
      children: _jsx(FullWidthImageCarousel, {
        dotColor: "bahia",
        children: infoLayout.map(function (item) {
          return item;
        })
      })
    }) : _jsx("div", {
      css: infoBannerContainer,
      children: infoLayout.map(function (item) {
        return item;
      })
    })]
  });
}
export default HomepageHeroBanner;